import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServicesTwo from '../components/Servicess/ServicesTwo'
import StartProject from '../components/Common/StartProject'

const Servicess = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="ERP Implementation & Data Migration Services"
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="ERP Implementation & Data Migration Services"
            />
            <ServicesTwo />
            <StartProject />
            <Footer />
        </Layout>
    );
}

export default Servicess