import React from 'react'
import {Link} from 'gatsby'

const ServicesTwo = () => {
    return (
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Order to Cash

                                </Link>
                            </h3>
                            <p>Implement Order to Cash business process with the help of our accelerators & experience gained in more than a decade of helping clients implement their SAP ECC/R3 systems.</p>

                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>
                                <Link to="/service-detailsa">
                                    Lead to Cash Order

                                </Link>
                            </h3>

                            <p>Helping you implement Lead to Cash process combining multiple SAP solutions enriched with AI & experience management scenarios.</p>
                            
                            <Link className="view-details-btn" to="/service-detailsa">
                                View Details
                            </Link>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-detailsb">
                                    Data Migration
                                </Link>
                            </h3>

                            <p>Data migration services to accelerate your upgrades, technology / vendor transitions & on-premise to cloud transformations.</p>
                            
                            <Link className="view-details-btn" to="/service-detailsb">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="/service-detailsc">
                                    Financial Accounting
                                </Link>
                            </h3>

                            <p>Helping you implement SAP Financial accounting solutions and optimizing your process to deliver reliable external reporting requirements.</p>
                            <Link className="view-details-btn" to="/service-detailsc">
                                View Details
                            </Link>
                       
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/service-detailsd">
                                    Controlling
                                </Link>
                            </h3>

                            <p>Helping you implement SAP CO module and optimizing your organizations reporting & management structures.</p>
                            <Link className="view-details-btn" to="/service-detailsd">
                                View Details
                            </Link>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/service-detailse">
                                    Materials Management

                                </Link>
                            </h3>

                            <p>Helping you implement SAP MM module and improve your materials, inventory and warehouse management solution.</p>
                            <Link className="view-details-btn" to="/service-detailse">
                                View Details
                            </Link>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/service-detailsf">
                                    Plant Maintenance
                                </Link>
                            </h3>

                            <p>Helping you implement/improve your SAP PM implementation across your organization, locations, sites & systems.</p>

                            <Link className="view-details-btn" to="/service-detailsf">
                                View Details
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/service-detailsg">
                                    Project Systems
                                </Link>
                            </h3>

                            <p>Implement SAP PS module & improve your Project Management process such as cost and budget management, scheduling, requisitioning of materials and services.</p>
                            <Link className="view-details-btn" to="/service-detailsg">
                                View Details
                            </Link>
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/service-detailsh">
                                    BEX & BW
                                </Link>
                            </h3>

                            <p>Helping you implement SAP Business Explorer & Business Warehouse solutions to improve your business decision making processes.</p>
                            <Link className="view-details-btn" to="/service-detailsh">
                                View Details
                            </Link>
                           
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/service-detailsz">
                                    BO & BODS
                                </Link>
                            </h3>

                            <p>Helping you implement Business Objects BI solution as well as Data Services for ETL using our expertise & accelerators for a quick win.</p>
                            <Link className="view-details-btn" to="/service-detailsz">
                                View Details
                            </Link>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesTwo